import gameConfig from '../../assets/config';
import stoneTexture0 from '../../assets/images/part_1.png';
import stoneTexture1 from '../../assets/images/part_2.png';
import stoneTexture2 from '../../assets/images/part_3.png';

import UbuntuBold from '../../assets/fonts/ubuntu/UBUNTU-B.TTF';
import UbuntuRegular from '../../assets/fonts/ubuntu/UBUNTU-R.TTF';
import MyriadPro from '../../assets/fonts/ubuntu/MYRIADPRO-BOLD.OTF';
import RevansMedium from '../../assets/fonts/Revans/Revans-Medium.ttf';
import { soundsPreloaderConfig } from '../enums/eSoundTypes';
import gameSizeConfig from '../configs/gameSizeConfig';
import { getAssetsScale } from 'Engine/utils/getAssetsScale';
import eAnimationTypes from '../animations/animationTypes';
import flashlib from '../../assets/exported/game/FlashLib.json';
import jackpotGameFlashLib from '../../assets/exported/jackpotGame/FlashLib.json';

const availableScales = ['0.7','0.75','0.8','0.9'];
const scale = getAssetsScale({
  availableScales,
  safeWidthPortrait: gameSizeConfig.SAFE_WIDTH_PORTRAIT,
  safeHeightPortrait: gameSizeConfig.SAFE_HEIGHT_PORTRAIT,
  safeWidthLandscape: gameSizeConfig.SAFE_WIDTH_LANDSCAPE,
  safeHeightLandscape: gameSizeConfig.SAFE_HEIGHT_LANDSCAPE,
})

const assets = `flashlib/game/AssetsCombined@${scale}x.json`;
const jackpotGameAssets = `flashlib/jackpotGame/AssetsCombined@${scale}x.json`;

// import assets from '../../assets/exported/game/FlashLibAssets';
// import jackpotGameAssets from '../../assets/exported/jackpotGame/FlashLibAssets.json'

export default {
  assets: [
    {name: 'GameAssets', path: assets, extension: 'json'},
    {name: 'GameFlashLib', path: flashlib, extension: 'json'},
    {name: 'jackpotGameAssets', path: jackpotGameAssets, extension: 'json'},
    {name: 'jackpotGameFlashLib', path: jackpotGameFlashLib, extension: 'json'},
    {name: 'gameConfig', path: gameConfig, extension: 'json'},
    {name: eAnimationTypes.SYMBOLS, path: 'animations/symbols/symbols_Joker_Coins_work.json', extension: 'json'},
    {name: 'background', path: 'animations/background/bg_Joker_coins_x-mas.json', extension: 'json'},
    {name: 'jackpot_panel', path: 'animations/jackpotPanel/skeleton.json', extension: 'json'},
    {name: 'BMFont', path: 'fonts/BMFont/BMFont.xml', extension: 'xml'},
    {name: 'stoneParticle0', path: stoneTexture0, extension: 'png'},
    {name: 'stoneParticle1', path: stoneTexture1, extension: 'png'},
    {name: 'stoneParticle2', path: stoneTexture2, extension: 'png'},
  ],
  delayedAssets: [
    {name: eAnimationTypes.ANTISIPATOR, path: 'animations/antisipator/skeleton_v2.json', extension: 'json'},
    {name: eAnimationTypes.BIG_WIN, path: 'animations/bigWins/DWB_b_o_e.json', extension: 'json'},
    {name: eAnimationTypes.JACKPOT, path: 'animations/jackpot/bonus_game.json', extension: 'json'},
  ],
  fonts: [
    { name: 'UbuntuBold', path: UbuntuBold },
    {
      name: 'UbuntuRegular',
      path: UbuntuRegular,
    },
    {
      name: 'Myriad Pro',
      path: MyriadPro,
    },
    {
      name: 'RevansMedium',
      path: RevansMedium,
    },
  ],
  sounds: soundsPreloaderConfig,
};
